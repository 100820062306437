import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  appDe, appEn, notFoundDe, notFoundEn, sideMenuDe, sideMenuEn, precheckPageDe, precheckPageEn, errorAlertDe, errorAlertEn,
} from './TranslationFiles';

const cookieName = '.AspNetCore.Culture';
const defaultLanguage = 'de-DE';

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length < 2) return undefined;

  const pop = parts.pop();
  if (!pop) return undefined;

  const first = pop.split(';').shift();
  if (!first) return undefined;

  return first;
};

const customDetector = {
  name: 'customDetector',

  lookup() {
    const cookie = getCookie(cookieName);
    if (!cookie) return defaultLanguage;

    // should be |uic, but don't know how to use | inside regex
    const result = cookie.match(/c=(.*)uic/);
    if (!result || result.length < 2) return defaultLanguage;

    // | at the end shouldn't be inside the culture code
    return result[1].substring(0, result[1].length - 1);
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

function setLanguageCookie(languageKey?: string): void {
  if (!languageKey) return;
  document.cookie = `${cookieName}=c=${languageKey}|uic=${languageKey}; SameSite=None; Secure`;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'all',
    supportedLngs: ['en-EN', 'de-DE'],
    fallbackLng: defaultLanguage,
    nonExplicitSupportedLngs: false,
    defaultNS: 'defaultTranslation',
    ns: ['App'],
    detection: {
      order: ['customDetector', 'cookie'],
      lookupCookie: cookieName,
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng).format(value);
        }
        if (format === 'intlCurrency') {
          return new Intl.NumberFormat(lng).format(value);
        }

        return value;
      },
    },
    react: {
      nsMode: 'default',
    },
  });

i18n.on('languageChanged', (lng) => {
  setLanguageCookie(lng);
});

i18n.addResourceBundle('de-DE', 'App', appDe, true, false);
i18n.addResourceBundle('en-EN', 'App', appEn, true, false);

i18n.addResourceBundle('de-DE', 'NotFound', notFoundDe, true, false);
i18n.addResourceBundle('en-EN', 'NotFound', notFoundEn, true, false);

i18n.addResourceBundle('de-DE', 'SideMenu', sideMenuDe, true, false);
i18n.addResourceBundle('en-EN', 'SideMenu', sideMenuEn, true, false);

i18n.addResourceBundle('de-DE', 'PreCheckPage', precheckPageDe, true, false);
i18n.addResourceBundle('en-EN', 'PreCheckPage', precheckPageEn, true, false);

i18n.addResourceBundle('de-DE', 'ErrorAlert', errorAlertDe, true, false);
i18n.addResourceBundle('en-EN', 'ErrorAlert', errorAlertEn, true, false);

export default i18n;

import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from './AppBar';

export type HeaderProps = {
  onSettingsOpened: () => void,
  sideMenuOpen: boolean,
  onSideMenuOpened: () => void,
}

const Header = ({ onSettingsOpened, sideMenuOpen, onSideMenuOpened }: HeaderProps): JSX.Element => (
  <AppBar
    position="fixed"
    open={sideMenuOpen}
    color="transparent"
    sx={(theme) => ({
      background: theme.palette.header.main,
    })}
  >
    <Toolbar>
      { /* Not needed until added more than one entry <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={onSideMenuOpened}
        edge="start"
        sx={{ mr: 2, ...(sideMenuOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton> */ }
      <Typography
        variant="h6"
        noWrap
        component="div"
      >
        COMPEON Pre-Check
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <IconButton
          size="large"
          aria-label="show settings"
          color="inherit"
          onClick={onSettingsOpened}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;

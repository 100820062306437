import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, RouteProps, Routes } from 'react-router-dom';
import {
  DrawerHeader,
  Header, Main, SettingsDrawer, SideMenuDrawer,
} from './components';
import Footer from './components/Footer';
import { getRouterRoutes } from '../pages/routes';
import { getTheme } from '../themes';
import { Settings } from '../domain/application';
import AdjustIframe from './components/IFrame';

const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const App = (): JSX.Element => {
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [settings, setSettings] = useState<Settings>({
    darkMode: false,
  });

  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

  const theme = getTheme(settings.darkMode);
  const routes = getRouterRoutes();

  if (inIframe()) {
    AdjustIframe();
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex', minHeight: '100vh',
      }}
      >
        <CssBaseline />
        {!inIframe() && (
          <>
            <Header
              onSettingsOpened={() => setSettingsOpen(true)}
              sideMenuOpen={sideMenuOpen}
              onSideMenuOpened={() => setSideMenuOpen(true)}
            />
            <SideMenuDrawer
              onSideMenuClose={() => setSideMenuOpen(false)}
              sideMenuOpen={sideMenuOpen}
              theme={theme}
            />
            <SettingsDrawer
              onSettingsClose={() => setSettingsOpen(false)}
              settingsOpen={settingsOpen}
              settings={settings}
              onSettingsChange={(changedSettings) => setSettings(changedSettings)}
            />
          </>
        )}

        <Main open={sideMenuOpen}>
          <Box sx={{
            height: '100%', position: 'relative',
          }}
          >
            {!inIframe() && (<DrawerHeader />)}
            <Routes>
              {
              routes.map((route: RouteProps): (React.ReactElement | undefined) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))
              }
            </Routes>
            {!inIframe() && (<Footer />)}
          </Box>

        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default App;

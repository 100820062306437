const repeatEvery = (delay: number, fn: () => void): NodeJS.Timeout => {
  fn.apply(null);
  return setTimeout((() => repeatEvery(delay, fn)), delay);
};

const postHeight = (): void => {
  const doc = document as unknown as HTMLCanvasElement;
  if (!window || window.top === null || !document) return;
  const docHeight = (doc.height !== undefined) ? doc.height : document.body.scrollHeight;
  window.top.postMessage(`height: ${docHeight}`, '*');
};

const AdjustIframe = (): NodeJS.Timeout => repeatEvery(100, postHeight);

export default AdjustIframe;

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

const Copyright = (): JSX.Element => (
  <Typography variant="body2" color="text.secondary" align="center">
    {`© ${new Date().getFullYear()} `}
    <Link color="inherit" underline="none" href="https://compeon.de/">
      COMPEON GmbH
    </Link>
  </Typography>
);

const Footer = (): JSX.Element => {
  const { t } = useTranslation('App', { useSuspense: false });

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        py: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
      }}
    >
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Copyright />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Link href="https://www.compeon.de/agb/" variant="body2" color="text.secondary" underline="none" sx={{ mx: 1 }}>
            {t('App:footer.terms')}
          </Link>
          <Link href="https://www.compeon.de/datenschutz/" variant="body2" color="text.secondary" underline="none" sx={{ mx: 1 }}>
            {t('App:footer.privacy')}
          </Link>
          <Link href="https://www.compeon.de/impressum/" variant="body2" color="text.secondary" underline="none" sx={{ mx: 1 }}>
            {t('App:footer.imprint')}
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;

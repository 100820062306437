import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    header: {
      main: '#000000',
    },
  },
  compeonLogo: {
    color: '#ffffff',
  },
};

export default themeOptions;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  test: string | undefined
}

const initialState: State = {
  test: '',
};

export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<string | undefined>) => {
      state.test = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTest,
} = testSlice.actions;

export default testSlice.reducer;

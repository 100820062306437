import React, { lazy, Suspense } from 'react';
import { PathRouteProps } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import RoutesConstants from '../constants/RoutesConstants';
import { SideMenuEntry } from '../domain/application';

const PreCheckPage = lazy(() => import('./PreCheck/PreCheckPage'));
const NotFoundPage = lazy(() => import('./NotFound/NotFoundPage'));

export function getRouterRoutes(): PathRouteProps[] {
  const routes: Array<PathRouteProps> = [
    {
      path: `${RoutesConstants.routePath.precheckPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <PreCheckPage />
        </Suspense>
      ),
    },

    // make sure that not found page is the last one, else it will be shown
    {
      element: (
        <Suspense fallback={<>...</>}>
          <NotFoundPage />
        </Suspense>
      ),
      path: RoutesConstants.routePath.notFoundPage,
    },
  ];
  return routes;
}

export function getSideMenuEntries(): SideMenuEntry[] {
  const sideMenuEntries: SideMenuEntry[] = [];

  sideMenuEntries.push({
    id: 1,
    icon: <SearchIcon />,
    titleResourcekey: 'SideMenu:entries.precheck',
    link: RoutesConstants.routePath.precheckPage,
  });

  return sideMenuEntries;
}
